import ModelBase from "@/apps/core/models/modelBase.js";

const modelName = "ujian";
const modelPath = `/${modelName}/`;

class Ujian extends ModelBase {
  constructor() {
    let model = {
      id: null,
      judul: "",
      pspd: "",
      stase: null,
      tanggal_ujian: null,
      rencana_ujian: null,
      jenis: null,
      penguji_set: [],
      mengulang: false
    };
    let requiredFields = [
      "stase", "judul", "rencana_ujian",
      "jenis", "penguji_set"
    ];
    super(modelName, model, requiredFields, ["tanggal_ujian"], modelPath);
  }

  getLoadData(data) {
    let tdata = super.getLoadData(data);
    tdata.stase = tdata.stase.id;
    tdata.jenis = tdata.jenis.id;
    return tdata;
  }

  getPayload() {
    let data = super.getPayload();
    data.penguji_set = data.penguji_set.map(obj => obj.id);
    return data;
  }
}

export default Ujian;
